.footer_modal {
    height: 520px;
    width: 100%;
}
  
.footer {
    background-color:#0A0A0A;
    color:#DDDDDD;
}

.footer-static-text,
.footer-text,
a.footer-text.active {
    color:#DDDDDD!important;
    text-decoration: none;
}
  
.footer-text:hover {
   color:#43B0B1!important;
}

.navbar-toggler:focus {
    box-shadow: none!important;
} 
  
.navbar-toggler:hover {
    background-color: #ffffff;
    color:#166b6b;
}

.navbar-collapse {
    align-items:baseline!important;
}
.nav-link,.nav-link:focus {
    color: #424243;
}

.nav-link:hover{
    color: #adaeaf;
    box-shadow: 0px 2px 0px 0px #adaeaf;
}

.nav-link.active {
    color: #166b6b;
    box-shadow: 0px 2px 0px 0px #053a47;
}

@media (max-width: 1019px) {
    .hide-mobile {
        display:none;
    }
}
