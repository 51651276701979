.btn-booking {
    background-color: #FBC403;
    color: #151515;
    font-size: 20px;
    font-weight: 300;
    border-radius: 5px;
    padding: 8px 16px !important;
    border: 0px;
    margin: 5px;
}

.btn-booking:hover {
    background-color: #FFDD03;
}

.btn-contact {
    background-color: white;
    color: #151515;
    font-size: 20px;
    font-weight: 300;
    border-radius: 5px;
    padding: 8px 16px !important;
    border: 1px solid black;
    margin: 5px;
}

.btn-contact:hover {
    background-color: #FFDD03;
    border: 1px solid #FFDD03;
}

.pill {
    background-color: #FBFCD4;
    border-radius: 30px;
    padding: 8px;
    margin: 8px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    width: fit-content;
    display: inline-flex;
    font-size: 14px;
    font-weight: 300;
}

h4 {
    color: #FFDD03;
    font-weight: 500;
}

.location-rate-icons {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}